import React, { Component, useState } from "react"
import countryList from 'react-select-country-list'
import API from "../../services/api"

class PersonalInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: props.firstname,
      lastName: props.lastname,
    }
  }
  render() {
    let { onChange } = this.props
    return (
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Delivery Information</h5>
            <div className="row text-left pt-4">
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="first-name">First name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="first-name"
                    name="firstName"
                    onChange={onChange}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="first-name">Last name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="last-name"
                    name="lastName"
                    onChange={onChange}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="phone">Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    onChange={onChange}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="personal-email">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="personal-email"
                    name="email"
                    onChange={onChange}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label for="personal-street">Street address</label>
                  <input
                    type="text"
                    className="form-control"
                    id="personal-street"
                    name="street"
                    onChange={onChange}
                    required
                  />
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="personal-street-2"
                    name="additionalStreet"
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="personal-city">City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="personal-city"
                    name="city"
                    onChange={onChange}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="personal-postcode">Postcode</label>
                  <input
                    type="text"
                    className="form-control"
                    id="personal-postcode"
                    name="postcode"
                    onChange={onChange}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="personal-state">State</label>
                  <input
                    type="text"
                    className="form-control"
                    id="personal-state"
                    name="state"
                    onChange={onChange}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label for="personal-country">Country</label>
                  <select
                    class="form-control"
                    id="personal-country"
                    name="country"
                    onChange={onChange}
                    required
                  >
                    <option value=""></option>
                    {countryList().getData().map((country) =>
                      <option value={country.label}>{country.label}</option>
                    )}
                  </select>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PersonalInfo
