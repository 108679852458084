import React, { Component } from "react"

export default class OrderSummary extends Component {
  constructor(props) {
    super(props)
    this.state = { subtotal: 0 }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subtotal !== this.props.subtotal) {
      this.setState({ subtotal: this.props.subtotal })
    }
  }
  render() {
    const {
      processingFees,
      discount,
      discountCode,
      ordertotal,
      discountSubmit,
      onChange,
      onSubmitDiscount,
      onProceedCheckout,
    } = this.props
    return (
      <div className="col-lg-5">
        <div className="card sticky-top">
          <div className="card-body">
            <h5 className="card-title">Order Summary</h5>
            <div className="row">
              <div className="col-lg-12 text-left pt-5">
                <div className="row">
                  <div className="col-md-7">Cart Subtotal :</div>
                  <div className="col-md-5 text-right">
                    RM{this.state.subtotal.toFixed(2)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7">Lab Processing Fees :</div>
                  <div className="col-md-5 text-right">
                    RM{processingFees.toFixed(2)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7">Discount :</div>
                  <div className="col-md-5 text-right">
                    RM{discount.toFixed(2)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7">Order total :</div>
                  <div className="col-md-5 text-right">
                    RM{ordertotal.toFixed(2)}
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-md-7">
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      placeholder="Have a discount code?"
                      name="discountCode"
                      value={discountCode}
                      onChange={onChange}
                      readOnly={discountSubmit == true ? true : false}
                    />
                  </div>
                  <div className="col-md-5 text-right">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      onClick={onSubmitDiscount}
                      disabled={discountSubmit == true ? true : false}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 pt-4">
                <button
                  type="submit"
                  class="btn btn-primary"
                  onClick={onProceedCheckout}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
