import React, { Component } from "react"
import Items from "../carts/items"
import AddMore from "../carts/addmore"
import API from "../../services/api"

export default class ItemContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      line_items: [],
      additional_addon_traits: [],
      first_name: "",
      last_name: "",
      email: "",
    }
    this.onChange = this.onChange.bind(this)
    this.onAddItem = this.onAddItem.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }

  onChange(e) {
    const { name, value } = e.target
    this.setState({
      [name]: value,
    })
  }

  onAddItem(cart_id, item) {
    var self = this
    API.addAddonItem(cart_id, item)
      .then(function(response) {
        self.fetchData()
        alert("Successfull added product to cart.")
        ++window.$itemCount
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    var self = this
    let { onSumSubtotal, cart_id } = this.props

    API.getCart(cart_id).then(function(response) {
      var tmpArrayAddonTraits = []
      var tmpArrayLineItems = []
      var additional_addon_subtotal = 0
      var line_items_subtotal = 0
      for (var i = 0; i < response.data.additional_addon_traits.length; i++) {
        if (response.data.additional_addon_traits[i].product.id != 40) {
          tmpArrayAddonTraits.push(response.data.additional_addon_traits[i])
        }
      }

      for (var i2 = 0; i2 < response.data.line_items.length; i2++) {
        tmpArrayLineItems.push(response.data.line_items[i2])
      }

      self.setState({
        additional_addon_traits: tmpArrayAddonTraits,
        line_items: tmpArrayLineItems,
      })
      var line_items_sub = document.getElementsByClassName("line-items-sub")

      for (var i3 = 0; i3 < line_items_sub.length; i3++) {
        var price = line_items_sub[i3].innerText
        line_items_subtotal += parseInt(price)
      }

      var additional_items_sub = document.getElementsByClassName(
        "additional-items-sub"
      )

      for (var i4 = 0; i4 < additional_items_sub.length; i4++) {
        var additional_price = additional_items_sub[i4].innerText
        additional_addon_subtotal += parseInt(additional_price)
      }

      var total_current_subtotal =
        additional_addon_subtotal + line_items_subtotal

      onSumSubtotal(total_current_subtotal)
    })
  }

  render() {
    let { additional_addon_traits, line_items } = this.state
    let { i } = this.props

    return (
      <>
        <div className="col-lg-12 pb-3">
          <div className="card text-left">
            <div className="card-body text-center">
              <div className="card-title text-left">Customer #{i + 1}</div>

              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}></th>
                    <th scope="col" colSpan={2}>
                      Product
                    </th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <Items
                    i={i}
                    items_arr={line_items}
                    additional_items_arr={additional_addon_traits}
                    fetchData={this.fetchData}
                  />
                  <AddMore
                    i={i}
                    cart_id={this.props.cart_id}
                    onAddItem={this.onAddItem}
                  />
                </tbody>
              </table>
              <div className="card text-left">
                <div className="card-body">
                  <div className="card-title">
                    <span>Customer #{i + 1} Account Information</span>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        name="firstname[]"
                        value={this.state["firstname_" + i]}
                        onChange={event =>
                          this.setState({
                            ["firstname_" + i]: event.target.value,
                          })
                        }
                        placeholder="First name"
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control"
                        name="lastname[]"
                        value={this.state["lastname_" + i]}
                        onChange={event =>
                          this.setState({
                            ["lastname_" + i]: event.target.value,
                          })
                        }
                        placeholder="Last name"
                        required
                      />
                    </div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        name="email[]"
                        className="form-control"
                        value={this.state["email_" + i]}
                        onChange={event =>
                          this.setState({
                            ["email_" + i]: event.target.value,
                          })
                        }
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>
                  <small className="pt-2" style={{ color: "#1bb1e7" }}>
                    Note : Account information cannot be same with others.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
