import React, { useState } from "react"

const AddMore = props => {
  const [item, setItem] = useState("")

  const onSubmit = () => {
    props.onAddItem(props.cart_id, item)
  }

  const onChangeInput = event => {
    const { value } = event.target
    setItem(value)
  }

  return (
    <tr>
      <td colSpan={3}>
        <select
          class="form-control"
          name="personCount"
          onChange={onChangeInput}
        >
          <option value="">Choose product for Customer #{props.i + 1}</option>
          <option value="1">DNA Explorer Prime</option>
          <option value="2">DNA Explorer Carrier</option>
          <option value="11">DNA Explorer Onco</option>
          <option value="3">DNA Explorer Personal - Health Risks Report</option>
          <option value="4">
            DNA Explorer Personal - Nutrigenomics Report
          </option>
          <option value="5">
            DNA Explorer Personal - Inner Potential Report
          </option>
          <option value="6">
            DNA Explorer Personal - Allergy & Sensitivity Report
          </option>
          <option value="7">
            DNA Explorer Personal - Weight Management Report
          </option>
          <option value="8">DNA Explorer Personal - Skin Report</option>
          <option value="9">DNA Explorer Personal - Fitness Report</option>
          <option value="10">DNA Explorer Personal - Carrier Report</option>
          <option value="12">DNA Explorer Personal - Cancer Report</option>
        </select>
      </td>
      <td>
        {" "}
        <button type="button" class="btn btn-primary" onClick={onSubmit}>
          Add more
        </button>
      </td>
    </tr>
  )
}

export default AddMore
