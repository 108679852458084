import React from "react"
import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import { Link, withPrefix } from "gatsby"
import { changeProgressBarOnScroll } from "../components/utils/index"
import PersonalInfo from "../components/carts/personalinfo"
import API from "../services/api"
import ContainerList from "../components/carts/containerlist"
import OrderSummary from "../components/carts/ordersummary"
import ReactModal from "react-modal"

export default class CartPage extends React.Component {
  constructor(props) {
    super(props)
    if (typeof window !== "undefined") {
      var localCart = JSON.parse(localStorage.getItem("advanx_cart_id"))
      var lineItem = JSON.parse(localStorage.getItem("advanx_line_items"))
      var localCartSize = localCart.length
    } else {
      var localCart = 0
      var lineItem = 0
    }
    this.state = {
      isModalOpen: false,
      cart_id: localCart,
      line_items: lineItem,
      subtotal: 0,
      processingFees: 0,
      discount: 0,
      discountCode: "",
      ordertotal: 0,
      personCount: localCartSize,
      discountSubmit: false,
      firstName: "",
      lastName: "",
      email: "",
      street: "",
      additionalStreet: "",
      city: "",
      state: "",
      country: "",
      phone: "",
    }
    this.onSumSubtotal = this.onSumSubtotal.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSubmitDiscount = this.onSubmitDiscount.bind(this)
    this.onProceedCheckout = this.onProceedCheckout.bind(this)
    this.onIncreasePerson = this.onIncreasePerson.bind(this)
    this.onDecreasePerson = this.onDecreasePerson.bind(this)
    this.increaseItem = this.increaseItem.bind(this)
  }

  componentDidMount() {
    var self = this
    const { cart_id } = this.state
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Static", "Cart")
    API.getDiscount(this.state.cart_id[0]).then(function(response) {
      if (response.data) {
        self.setState(preState => {
          return {
            discount: parseInt(response.data.discount.amount / 100),
            discountSubmit: true,
            discountCode: response.data.discount.name,
            ordertotal:
              preState.ordertotal -
              parseInt(response.data.discount.amount / 100),
          }
        })
      }
    })
    API.getCartTotal(cart_id[0]).then(function(response) {
      localStorage.setItem("advanx_items_count", response.data)
    })
    var status = window.location.search.split("status=")[1]
    if (status == "paid") {
      self.setState(preState => {
        return {
          isModalOpen: true,
        }
      })
      localStorage.setItem("advanx_cart_id", "[]")
      localStorage.setItem("cart_total_price", "0")
    }
  }

  onSumSubtotal(price) {
    var self = this
    var temp_price = price == null ? this.state.subtotal : price
    var calculate_sum = temp_price + this.state.processingFees
    var carts = this.state.cart_id

    API.getPrice(carts[0]).then(function(response) {
      console.log(response.data)
      localStorage.setItem(
        "advanx_processing_fees",
        response.data.processing_fees
      )
      var processingFees = parseInt(
        localStorage.getItem("advanx_processing_fees")
      )

      self.setState(preState => {
        return {
          subtotal: temp_price,
          ordertotal: processingFees + temp_price - preState.discount,
          processingFees: processingFees,
        }
      })
    })
  }

  onChange(e) {
    const { name, value } = e.target
    this.setState({
      [name]: value,
    })
  }

  onDecreasePerson() {
    var self = this
    var last_arr = JSON.parse(localStorage.getItem("advanx_cart_id"))
    this.setState({
      personCount: self.state.personCount - 1,
    })
    API.removeAddMoreCart(last_arr.slice(-1))
      .then(function(response) {
        last_arr.pop()
        localStorage.setItem("advanx_cart_id", JSON.stringify(last_arr))
        self.setState({ cart_id: last_arr })
        window.location.reload()
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  onIncreasePerson() {
    var self = this
    var cart_id = JSON.parse(localStorage.getItem("advanx_cart_id"))

    this.setState({
      personCount: self.state.personCount + 1,
    })
    API.createAddMoreCart(cart_id[0])
      .then(function(response) {
        cart_id.push(response.data.id)
        localStorage.setItem("advanx_cart_id", JSON.stringify(cart_id))
        self.setState({ cart_id: cart_id })
      })
      .catch(function(error) {
        console.log(error)
      })
  }

  onSubmitDiscount() {
    const self = this
    const { discountCode, ordertotal, cart_id } = this.state

    API.applyDiscount(cart_id, discountCode).then(function(response) {
      console.log(response.data.message)
      self.setState({
        discount: parseInt(response.data.amount / 100),
        ordertotal: ordertotal - parseInt(response.data.amount / 100),
        discountSubmit: true,
      })
    })
  }

  increaseItem() {
    const { cart_id } = this.state
    API.getCartTotal(cart_id[0]).then(function(response) {
      localStorage.setItem("advanx_items_count", response.data)
      ++window.$itemCount
    })
  }

  onProceedCheckout() {
    const {
      firstName,
      lastName,
      phone,
      email,
      street,
      additionalStreet,
      city,
      postcode,
      state,
      country,
      cart_id,
      ordertotal,
    } = this.state

    var firstname_arr = []
    var lastname_arr = []
    var email_arr = []
    let firstNamehasValue = 0
    let lastNamehasValue = 0
    let emailHasValue = 0

    var elements = document.getElementsByName("firstname[]")
    for (var i = 0, im = elements.length; im > i; i++) {
      firstname_arr.push(elements[i].value)
      if (elements[i].value !== "") {
        firstNamehasValue += 1
      }
    }

    var elements_2 = document.getElementsByName("lastname[]")
    for (var i = 0, im = elements_2.length; im > i; i++) {
      lastname_arr.push(elements_2[i].value)
      if (elements_2[i].value !== "") {
        lastNamehasValue += 1
      }
    }

    var elements_3 = document.getElementsByName("email[]")
    for (var i = 0, im = elements_3.length; im > i; i++) {
      email_arr.push(elements_3[i].value)
      if (elements_3[i].value !== "") {
        emailHasValue += 1
      }
    }

    if (
      firstNamehasValue == firstname_arr.length &&
      lastNamehasValue == lastname_arr.length &&
      emailHasValue == email_arr.length &&
      firstName != "" &&
      lastName != "" &&
      phone != "" &&
      email != "" &&
      street != "" &&
      city != "" &&
      postcode != "" &&
      state != "" &&
      country != ""
    ) {
      API.createCheckout(
        firstName,
        lastName,
        phone,
        email,
        street,
        additionalStreet,
        city,
        postcode,
        state,
        country,
        cart_id,
        firstname_arr,
        lastname_arr,
        email_arr,
        ordertotal
      )
    } else {
      alert("Please complete all input fields!")
    }
  }

  handleModalOpen = event => {
    this.setState({ isModalOpen: true })
  }

  handleModalClose = event => {
    this.setState({ isModalOpen: false })
    window.location.href = "/cart"
  }

  render() {
    const { carts } = pageData
    const {
      subtotal,
      processingFees,
      discount,
      discountCode,
      ordertotal,
      personCount,
      discountSubmit,
      cart_id,
    } = this.state

    return (
      <Layout>
        <ReactModal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.handleModalClose}
          contentLabel="Conditions"
          className="ImageModal"
          ariaHideApp={false}
        >
          <div className="text-center m-3">
            <img
              className="img-fluid"
              src={withPrefix("img/successful.png")}
              style={{ maxHeight: "100px" }}
              alt="payment success"
            />
            <div className="content">
              <label className="pt-2">Thank you, Payment successful!</label>
              <label>
                Please check your email for further information about your
                payment.
              </label>
              <div className="text-left pt-4">
                <small>
                  You may login your account{" "}
                  <u>
                    <a href="https://app.advanxhealth.com">here</a>
                  </u>
                </small>
                <br />
                <small>
                  <i>
                    Note: Please use forgot password function for your first
                    login.
                  </i>
                </small>
              </div>
              <div className="pt-3">
                <button
                  onClick={this.handleModalClose}
                  className="btn btn-lg btn-primary"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
        <div className="faq-page">
          <HeadMeta metadata={pageData.metadata} />
          <ProgressBar />
          <section
            className="hero hero-landing bg-gradient-blue-green"
            style={{ minHeight: "50vh" }}
          >
            <div className="container text-white">
              <header className="text-center mb-4">
                <h1>Shopping Cart</h1>
                <p className="lead">Check your product.</p>
              </header>
            </div>
          </section>
          <section className="py-5">
            <div className="container text-center">
              <div className="row">
                <div className="col-md-12 pb-4">
                  <div className="row">
                    <div className="col-lg-12">
                      <div class="form-group text-left">
                        <div className="row">
                          <div className="col-md-8">
                            <button
                              type="button"
                              class="btn btn-info btn-circle"
                              onClick={this.onDecreasePerson}
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                            <span className="p-3">{personCount} Customer</span>
                            <button
                              type="button"
                              className="btn btn-info btn-circle"
                              onClick={this.onIncreasePerson}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <ContainerList
                    cart_id={cart_id}
                    onSumSubtotal={this.onSumSubtotal}
                    subtotal={subtotal}
                    increaseItem={this.increaseItem}
                  />
                  <div className="col-lg-7">
                    <div className="row">
                      <PersonalInfo onChange={this.onChange} />
                    </div>
                  </div>
                  <OrderSummary
                    subtotal={subtotal}
                    processingFees={processingFees}
                    discount={discount}
                    ordertotal={ordertotal}
                    discountCode={discountCode}
                    onChange={this.onChange}
                    discountSubmit={discountSubmit}
                    onSubmitDiscount={this.onSubmitDiscount}
                    onProceedCheckout={this.onProceedCheckout}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "Shopping Cart | Advanx Health",
    description:
      "Discover your DNA with comprehensive DNA tests and get personalised recommendations for health risks, nutrition traits, fitness traits and more!",
    image: "https://www.advanxhealth.com/img/share/power-of-genes.png",
    url: "https://www.advanxhealth.com/cart",
  },
}
