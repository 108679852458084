import React, { Component } from "react"
import API from "../../services/api"

export default class Items extends Component {
  constructor(props) {
    super(props)
    this.state = { img: "", title: "" }
    this.onRemoveItem = this.onRemoveItem.bind(this)
  }

  componentDidMount() {
    let { fetchData } = this.props
    fetchData()
  }

  onRemoveItem(id, type) {
    let { fetchData } = this.props

    API.removeItem(id, type).then(function(response) {
      if (response) {
        fetchData()
        --window.$itemCount
        window.location.reload()
      }
    })
  }

  render() {
    var self = this
    const { items_arr, additional_items_arr } = this.props
    return (
      <>
        {Object.keys(items_arr).map((key, i) => (
          <tr key={i}>
            <td className="text-center">
              <button
                type="button"
                className="btn btn-danger btn-circle"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this item?")
                  )
                    self.onRemoveItem(items_arr[key].id, "package")
                }}
              >
                <i className="fa fa-times"></i>
              </button>
            </td>
            <td colSpan={2}>
              {items_arr[key].package == null ? (
                items_arr[key].product.name.includes("Carrier") ? (
                  <img src="/img/logo/DNA-Explorer-Carrier.png" width="150" />
                ) : items_arr[key].product.name.includes("Prime") ? (
                  <img src="/img/logo/DNA-Explorer-Prime.png" width="150" />
                ) : items_arr[key].product.name.includes("ONCO") ? (
                  <img src="/img/logo/DNA-Explorer-Onco.png" width="150" />
                ) : (
                  <div>
                    <img
                      src="/img/logo/DNA-Explorer-Personal.png"
                      width="150"
                    />
                    <br />

                    <small>{items_arr[key].product.name}</small>
                  </div>
                )
              ) : items_arr[key].package.name.includes("Carrier") ? (
                <img src="/img/logo/DNA-Explorer-Carrier.png" width="150" />
              ) : items_arr[key].package.name.includes("Prime") ? (
                <img src="/img/logo/DNA-Explorer-Prime.png" width="150" />
              ) : items_arr[key].package.name.includes("ONCO") ? (
                <img src="/img/logo/DNA-Explorer-Onco.png" width="150" />
              ) : (
                items_arr[key].package.name
              )}
            </td>
            <td>
              RM
              <span className="line-items-sub">
                {(
                  parseInt(
                    items_arr[key].subtotal_amount_cents == null
                      ? items_arr[key].product.price
                      : items_arr[key].subtotal_amount_cents
                  ) / 100
                ).toFixed(2)}
              </span>
            </td>
          </tr>
        ))}
        {Object.keys(additional_items_arr).map((key, i) => (
          <tr key={i}>
            <td className="text-center">
              <button
                type="button"
                className="btn btn-danger btn-circle"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this item?")
                  )
                    self.onRemoveItem(additional_items_arr[key].id, "ala_carte")
                }}
              >
                <i className="fa fa-times"></i>
              </button>
            </td>
            <td colSpan={2}>
              {additional_items_arr[key].package == null ? (
                additional_items_arr[key].product.name.includes("Carrier") ? (
                  <img src="/img/logo/DNA-Explorer-Carrier.png" width="150" />
                ) : additional_items_arr[key].product.name.includes("Prime") ? (
                  <img src="/img/logo/DNA-Explorer-Prime.png" width="150" />
                ) : (
                  <div>
                    <img
                      src="/img/logo/DNA-Explorer-Personal.png"
                      width="150"
                    />
                    <br />

                    <small>{additional_items_arr[key].product.name}</small>
                  </div>
                )
              ) : additional_items_arr[key].package.name.includes("Carrier") ? (
                <img src="/img/logo/DNA-Explorer-Carrier.png" width="150" />
              ) : additional_items_arr[key].package.name.includes("Prime") ? (
                <img src="/img/logo/DNA-Explorer-Prime.png" width="150" />
              ) : (
                additional_items_arr[key].package.name
              )}
            </td>
            <td>
              RM
              <span className="additional-items-sub">
                {(
                  parseInt(
                    additional_items_arr[key].subtotal_amount_cents == null
                      ? additional_items_arr[key].product.price
                      : additional_items_arr[key].subtotal_amount_cents
                  ) / 100
                ).toFixed(2)}
              </span>
            </td>
          </tr>
        ))}
      </>
    )
  }
}
