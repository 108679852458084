import React, { Component } from "react"
import ItemContainer from "./itemcontainer"

export default class ContainerList extends Component {
  constructor(props) {
    super(props)
    this.state = { cart_id: [] }
  }

  componentDidMount() {
    var self = this
    this.setState({ cart_id: self.props.cart_id })
  }

  componentDidUpdate(props) {
    const { cart_id } = this.props
    if (props.cart_id !== cart_id) {
      this.setState({ cart_id: cart_id })
    }
  }

  render() {
    let { cart_id } = this.state
    let { increaseItem, onSumSubtotal, subtotal } = this.props
    return (
      <>
        {cart_id.map((cart, i) => (
          <ItemContainer
            key={i}
            cart_id={cart}
            onSumSubtotal={onSumSubtotal}
            subtotal={subtotal}
            increaseItem={increaseItem}
            i={i}
          />
        ))}
      </>
    )
  }
}
